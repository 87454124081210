import React, { useState } from "react";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import "./Dates.css";
import DatesItem from "../../individual/DatesItem/DatesItem";

const Dates = () => {
  const [closeAllCalendars, setCloseAllCalendars] = useState(false);

  return (
    <div
      className="dates__pater"
      id="datesclaus"
      onClick={() => {
        setCloseAllCalendars(true);
      }}
    >
      <div className="dates__container">
        <SectionHeader title="Dates" />
        <div className="dates__items-container">
          <DatesItem
            id={1}
            text="Data límit per a la presentació dels abstracts previs a les comunicacions i comunicacions breus"
            date="30/04/2024"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
          />
          <DatesItem
            id={2}
            text="Notificació als interessats de l’acceptació de les propostes"
            date="31/05/2024"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
          />
          <DatesItem
            id={3}
            text="Data límit per a la presentació de les comunicacions i comunicacions breus definitives"
            date="31/10/2024"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
          />
          <DatesItem
            id={4}
            text="Notificació als interessats de l'acceptació de les comunicacions definitives"
            date="30/11/2024"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
          />
          <DatesItem
            id={5}
            text="Sessió final"
            date="20/06/2025"
            closeAllCalendars={closeAllCalendars}
            setCloseAllCalendars={setCloseAllCalendars}
          />
        </div>
      </div>
    </div>
  );
};

export default Dates;
